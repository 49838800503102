import * as React from 'react'

import {
  Autocomplete,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import {
  AssetBadge,
  ButtonContainer,
  ButtonsContainer,
  Currency,
  MarketAssetAdornment,
  OptionDisplay,
  OptionTextField,
} from '../../components'
import {
  getPriceGraphData,
  getTotalAmount,
  isValidAssetAmount,
  withSymbol,
} from '../../services'

import type {
  AssetPrice,
  MarketAsset,
  UserOperation,
} from '../../queries'
import type { FormikProps } from 'formik'

type FormValues = {
  amount: number
}

const initialValues = (amount: number): FormValues => ({
  amount: amount,
})

const validationSchema = (totalAmount: number): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    amount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .max(totalAmount, 'Debes ingresar un monto menor a tu balance')
      .test('validFormat', 'Introduce un número con máximo 6 decimales', isValidAssetAmount),
  })

type InnerFormProps = FormikProps<FormValues> & {
  assetUsdPrice?: number
  symbol: string
}

const InnerForm = ({
  isSubmitting,
  isValid,
  values,
  submitForm,
  assetUsdPrice,
  symbol,
}: InnerFormProps) => (
  <Form>
    <Field
      required
      name='amount'
      type='number'
      label={`Monto a retirar en ${symbol}`}
      component={TextField}
      inputProps={{
        autoComplete: 'off',
        inputMode: 'numeric',
      }}
      InputProps={{
        onClick: (event: React.MouseEvent<HTMLInputElement>) => {
          event.currentTarget.querySelector('input')?.select()
        },
      }}
      margin='normal'
      fullWidth
    />
    <Typography
      variant='body2'
      textAlign='right'
    >
      Aproximadamente&nbsp;
      <Currency
        currency='USD'
        value={
          (typeof assetUsdPrice === 'undefined')
            ? undefined
            : values.amount * assetUsdPrice
        }
      />
    </Typography>
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type FormWrapperProps = {
  marketAsset: MarketAsset | null
  usdPrices: AssetPrice[]
  operations: UserOperation[]
  amount: number
  handleSubmit: (marketAsset: MarketAsset, amount: number) => void
}

const FormWrapper = ({
  marketAsset,
  usdPrices,
  operations,
  amount,
  handleSubmit,
}: FormWrapperProps) => {
  if (!marketAsset) {
    return null
  }

  const symbol = marketAsset.symbol

  const assetOperations = withSymbol(operations, symbol)
  const totalAmount = getTotalAmount(symbol, assetOperations)

  const graphData = getPriceGraphData(usdPrices, symbol)

  return (
    <React.Fragment>
      <List disablePadding>
        <ListItem disableGutters>
          <ListItemText primary='Balance actual' />
          <ListItemText
            primary={(
              <Currency
                currency={symbol}
                value={totalAmount}
              />
            )}
            primaryTypographyProps={{ textAlign: 'right' }}
          />
        </ListItem>
      </List>
      <Formik
        initialValues={initialValues(amount)}
        validationSchema={validationSchema(totalAmount)}
        onSubmit={(values) => handleSubmit(marketAsset, values.amount)}
      >
        {(props) => (
          <InnerForm
            {...props}
            assetUsdPrice={graphData.lastValue}
            symbol={symbol}
          />
        )}
      </Formik>
    </React.Fragment>
  )
}

type AmountStepProps = {
  marketAssets: MarketAsset[]
  usdPrices: AssetPrice[]
  operations: UserOperation[]
  marketAsset: MarketAsset | null
  amount: number
  handleNext: (marketAsset: MarketAsset, amount: number) => void
}

export const AmountStep = ({
  marketAssets,
  usdPrices,
  operations,
  marketAsset,
  amount,
  handleNext,
}: AmountStepProps) => {
  const [selectedMarketAsset, setMarketAsset] = React.useState<MarketAsset | null>(marketAsset)

  return (
    <React.Fragment>
      <Typography
        variant='h6'
        textAlign='center'
        pb={2}
      >
        Retirar al blockchain
      </Typography>
      <Autocomplete
        options={marketAssets.filter((asset) => asset.blockchains.length > 0)}
        getOptionLabel={(option) => option.name}
        value={selectedMarketAsset}
        onChange={(_event, newValue) => setMarketAsset(newValue)}
        renderOption={(props, option) => (
          <OptionDisplay {...props}>
            <AssetBadge
              symbol={option.symbol}
              height={20}
            />
            {option.name}
          </OptionDisplay>
        )}
        renderInput={(params) => (
          <OptionTextField
            startAdornment={<MarketAssetAdornment symbol={selectedMarketAsset?.symbol} />}
            name='marketAsset'
            label='Selecciona una criptomoneda'
            params={params}
          />
        )}
        disablePortal
      />
      <Collapse in={!!selectedMarketAsset}>
        <FormWrapper
          marketAsset={selectedMarketAsset}
          usdPrices={usdPrices}
          operations={operations}
          amount={amount}
          handleSubmit={handleNext}
        />
      </Collapse>
    </React.Fragment>
  )
}
